<template>
    <div class="dashboard-hero text-white">
        <img :src="heroImage" alt="crossworx" class="dashboard-hero-image" />
        <div class="dashboard-hero-content d-flex justify-content-between">
            <div class="user-avatar ps-3 pt-1">
                <img :src="avatarUrl" class="user-avatar-image">
            </div>
            <div class="user-info-right d-flex flex-column justify-content-end px-3 py-2">
                <div class="user-qr mb-2">
                    <qr-code
                            class="qr-code"
                            ref="heroQr"
                            :contents="user.tp_number"
                            module-color="#111"
                            position-ring-color="#c93a36"
                            position-center-color="#c93a36"
                            style="opacity: 0"
                            @click="showCwxIdQrModal = !showCwxIdQrModal"
                    >
                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                        <img src="@/assets/images/brand/x.svg" alt="qr" slot="icon" class="qr-logo" />
                    </qr-code>
                </div>
                <div class="user-cwx-id">
                    {{ user.tp_number }}
                </div>
                <div class="user-joined">
                    <small>Member since: {{ formatDate(user.insertDate) }}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="container position-relative">
        <div class="xpowered-icon position-absolute" v-if="user.customizedPWA" style="right:10px; top:-30px; width: 35px;">
            <img src="@/assets/images/brand/xpowered.png" alt="xPowered" class="img-fluid" />
        </div>

        <div class="welcome-text">
            Hello, <span class="text-primary">{{ user.first_name || user.company || "User" }}</span>!
        </div>

        <div v-if="stats" class="dashboard-stats mb-5">
            <transition name="element-fade" @after-enter="showNext('stats2')">
                <div v-if="animated.stats1.visible" class="stats-row stats-row-bonus">
                    <h3 class="mt-3 mb-2 text-nowrap">Your Achieved Bonus</h3>
                    <div class="d-flex gap-2 stats-cols">
                        <div class="col-auto d-flex flex-column align-items-center justify-content-center stats-col stats-current">
                            <h5>Current Month</h5>
                            <span class="stats-bonus-amount amount-current text-nowrap">{{ stats.bonus }} €*</span>
                        </div>
                        <div class="col d-flex flex-column gap-2">
                            <div class="d-flex align-items-center justify-content-center flex-wrap stats-col stats-year">
                                <h5 class="d-inline me-2 text-nowrap">This Year</h5>
                                <span class="text-nowrap stats-bonus-amount amount-year">{{ stats.bonusYear }} €*</span>
                            </div>
                            <div class="d-flex flex-wrap gap-2">
                                <div class="col d-flex align-items-center stats-col stats-paid">
                                    <div class="stats-inner">
                                        <h5>Paid Out</h5>
                                        <span class="stats-bonus-amount amount-paid text-nowrap">{{ stats.bonusPaid }} €</span>
                                    </div>
                                </div>
                                <div class="col d-flex align-items-center stats-col stats-open">
                                    <div class="stats-inner">
                                        <h5>Open</h5>
                                        <span class="stats-bonus-amount amount-open text-nowrap">{{ stats.bonusOpen }} €</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </transition>

            <transition name="element-fade" @after-enter="showNext('stats3')">
                <div v-if="animated.stats2.visible" class="stats-row">
                    <div class="row">
                        <div class="col-8">
                            <h3>Your Community</h3>
                            <p><span class="h1 fw-bolder">{{ stats.community }}</span> Members</p>
                        </div>
                        <div class="col-4">
                            <h3><img class="coinx-logo" src="@/assets/images/brand/coinx.svg"></h3>
                            <p class="h1">{{ stats.coinx }}</p>
                        </div>
                    </div>
                </div>
            </transition>

            <transition name="element-fade" @after-enter="showNext('ads')">
                <div v-if="animated.stats3.visible" class="stats-row">
                    <div class="row" v-if="stats.dailyInviteBonusInfo && stats.dailyInviteBonusInfo.maxInvite">
                        <div class="mb-1">Daily target additional bonus for new community members</div>
                        <p class="d-flex align-items-center justify-content-between">
                            <span class="h1 text-primary fw-bolder">
                                {{ stats.dailyInviteBonusInfo.invited }} / {{ stats.dailyInviteBonusInfo.maxInvite }}
                            </span>
                            <span class="h5 bg-secondary text-white px-3 py-1">
                                {{ stats.dailyInviteBonusInfo.amount }} € of possible {{ stats.dailyInviteBonusInfo.maxAmount }} €
                            </span>
                        </p>
                    </div>
                </div>
            </transition>
        </div>

        <transition name="element-fade">
            <div v-if="animated.ads.visible" class="dashboard-ads mb-5">
                <Splide class="ad-slider" :options="{ rewind: true, padding: '20vw', gap: '1.5rem', pagination: false, autoplay: true, lazyLoad: 'nearby' }" aria-label="My Favorite Images">
                    <SplideSlide v-for="(ad, i) in ads" v-bind:key="i">
                        <a class="ad-slide" :href="ad.linkUrl" target="_blank">
                            <img class="img-fluid" :src="ad.imageUrl" height="350" alt="">
                        </a>
                    </SplideSlide>

                </Splide>
            </div>
        </transition>

        <div class="dashboard-accordion mb-5">

            <accordion id="accordion-1" classes="accordion-flush">

                <accordion-item id="accordion-produktpartner-news" @accordion-opened="fetchProdctPartnerNews">
                    <template #header-button>
                        Product Partner News
                    </template>
                    <template #body>
                        <template v-if="partnerNews.length">
                            <div v-for="item in partnerNews" v-bind:key="item" class="partner-news-item">
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                    <img v-if="item.logo" :src="getImageOrPlaceholderUrl(item.logo)" :alt="item.company" class="partner-news-item-logo img-fluid">
                                    <strong v-else>{{ item.company }}</strong>
                                    <br>
                                    <small>{{ formatDate(item.created_at) }}</small>
                                </div>
                                <h5>{{ item.title }}</h5>
                                <div v-html="item.content"></div>
                                <div class="d-grid">
                                    <a class="btn btn-primary" :href="item.cta_target" target="_blank">{{ item.cta_text }}</a>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            There are currently no active product partner news
                        </template>
                    </template>
                </accordion-item>

                <accordion-item id="accordion-inspirationen" @accordion-opened="fetchBlogPosts">
                    <template #header-button>
                        Inspirations
                    </template>
                    <template #body>
                        <template v-if="inspiration">

                            <Splide class="ad-slider" :options="{ rewind: true, padding: '5vw', gap: '1.5rem', autoWidth: true, perMove: 1, pagination: false, lazyLoad: 'nearby' }" aria-label="Inspiration">
                                <SplideSlide v-for="(post, i) in inspiration" v-bind:key="i" >
                                    <div class="card" style="width: 18rem; height: 30rem">
                                        <img :src="post.enclosure.url" class="card-img-top" alt="...">
                                        <div class="card-body d-flex flex-column">
                                            <h5 class="card-title">{{decodeHTML(post.title)}}</h5>
                                            <p class="card-text">{{decodeHTML(post.description)}}</p>
                                            <div class="flex-grow-1 d-flex align-items-end">
                                                <div class="flex-grow-1 d-grid">
                                                    <a @click="showModal(post)" class="btn btn-primary text-white">
                                                        Read more
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                            </Splide>


                        </template>
                        <template v-else>
                            [Inspirations will be displayed here]
                        </template>

                    </template>
                </accordion-item>

                <accordion-item id="accordion-meine-empfehlungsliste" @accordion-opened="fetchTransactions">
                    <template #header-button>
                        My Recommendation List
                    </template>
                    <template #body>
                        <template v-if="transactions && transactions.length">
                            <p>Your recommendations from the last 30 days are displayed here.</p>
                            <div v-for="transaction in transactions" v-bind:key="transaction" class="transaction-item d-flex gap-3">
                                <div class="transaction-logo">
                                    <img :src="getImageOrPlaceholderUrl(transaction.pp_logo)" :alt="transaction.pp_company" class="transaction-logo-image">
                                </div>
                                <div class="transaction-details flex-grow-1">
                                    <strong>{{ transaction.pp_company }}</strong><br>
                                    <span>{{ transaction.pp_city }}</span><br>
                                    <div class="d-flex">
                                        <small class="flex-grow-1">{{ transaction.date }} Uhr</small>
                                        <div class="text-end">
                                            <strong>{{ currency(transaction.bonus) }}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            You have no recommendations in the last 30 days.
                        </template>
                    </template>
                </accordion-item>

                <accordion-item id="accordion-meine-firstline" @accordion-opened="fetchFirstline">
                    <template #header-button>
                        My Firstline
                    </template>
                    <template #body>
                        <template v-if="firstline">
                            <p>Here you will find your firstline in chronological order. These are all the people you have personally invited into your network.</p>
                            <div v-for="year in firstline" v-bind:key="year" class="mb-4">
                                <div class="mb-3">
                                    <strong class="badge bg-primary">{{ year.year }}</strong>
                                </div>
                                <Splide class="firstline-slider" :options="{ perPage: 2, rewind: true, autoHeight: true, gap: '10px', pagination: false }" aria-label="Firstline">
                                    <SplideSlide v-for="(child, i) in year.children" v-bind:key="i">
                                        <div class="card">
                                            <div class="card-body d-flex flex-column align-items-center">
                                                <img :src="getImageOrPlaceholderUrl(child.avatar)" :alt="child.company" class="partner-avatar-image mb-2">
                                                <span class="badge-new badge bg-primary" v-if="child.new">NEW</span>
                                                <strong class="text-nowrap overflow-hidden" style="text-overflow: ellipsis;max-width: 100%;">{{ child.company }}</strong>
                                                <small>Member since {{ child.insertDate }}</small>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                </Splide>
                            </div>
                        </template>
                        <template v-else>
                            You haven't recruited any members yet.
                        </template>
                    </template>
                </accordion-item>

                <accordion-item id="accordion-coinx-transaktionen" @accordion-opened="fetchCoinxLog">
                    <template #header-button>
                        coinX Transactions
                    </template>
                    <template #body>
                        <template v-if="coinxlog && coinxlog.length">
                            <p>Your coinX transactions from the last 30 days are displayed here.</p>
                            <div v-for="entry in coinxlog" v-bind:key="entry" class="transaction-item d-flex gap-3">
                                <div class="transaction-logo">
                                    <img src="@/assets/images/brand/coinx.svg" :alt="entry.sender" class="transaction-logo-image">
                                </div>
                                <div class="transaction-details flex-grow-1">
                                    <strong>{{ entry.sender }}</strong><br>
                                    <em>{{ entry.comment }}</em><br>
                                    <div class="d-flex">
                                        <small class="flex-grow-1">{{ entry.date }} Uhr</small>
                                        <div class="text-end">
                                            <strong>{{ entry.value }} cX</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            There are no coinX transactions in the last 30 days.
                        </template>
                    </template>
                </accordion-item>

                <accordion-item id="accordion-meine-nfts" :disabled="true">
                    <template #header-button>
                        My NFTs
                    </template>
                    <template #body>
                        There are no NFTs yet
                    </template>
                </accordion-item>

            </accordion>

        </div>

        <div class="dashboard-storelocator mb-5">
            <storelocator></storelocator>
        </div>

        <div class="dashboard-accordion mb-5">
            <h3>My Profile</h3>

            <router-link to="/profile" custom v-slot="{ navigate, href }">
                <button @click="navigate" :href="href" class="btn btn-accordion">
                    View or adjust
                </button>
            </router-link>

        </div>

        <div class="dashboard-accordion mb-3" v-if="cmsContent.default">
            <h3>Help Center</h3>
            <accordion id="accordion-hilfezentrum" classes="accordion-flush">
                <accordion-item :id="'accordion-' + item.identifier" v-for="item in cmsContent.default" v-bind:key="item">
                    <template #header-button>
                        {{ item.title }}
                    </template>
                    <template #body>
                        <div v-html="item.content"></div>
                    </template>
                </accordion-item>
            </accordion>
        </div>

        <div class="dashboard-accordion mb-4" v-if="cmsContent.legal">
            <h3>Legal Information</h3>
            <accordion id="accordion-rechtliches" classes="accordion-flush">

                <accordion-item :id="'accordion-' + item.identifier" v-for="item in cmsContent.legal" v-bind:key="item">
                    <template #header-button>
                        {{ item.title }}
                    </template>
                    <template #body>
                        <div v-html="item.content"></div>
                    </template>
                </accordion-item>
            </accordion>
        </div>

        <div class="d-grid mb-3">
            <button class="btn btn-primary btn-lg" @click="logout">Log out</button>
        </div>

    </div>

    <BlogPostModalComponent

            :show="modalVisible"
            :item="selectedItem"
            @close="closeModal"
    />

    <modal v-model:showModal="showCwxIdQrModal" title="Your crossworx ID" class="text-center">
        <template #body>
            <div class="mb-3">
                Please show this QR code to our product partners.
            </div>

            <qr-code
                    class="qr-code"
                    ref="modalQr"
                    :contents="user.tp_number"
                    module-color="#111"
                    position-ring-color="#c93a36"
                    position-center-color="#c93a36"
            >
                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                <img src="@/assets/images/brand/x.svg" alt="qr" slot="icon" class="qr-logo" />
            </qr-code>

            <div>
                By scanning this QR code, you generate your referral bonus.
            </div>
        </template>
    </modal>

</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Storelocator from '@/views/components/StorelocatorComponent.vue';
import BlogPostModalComponent from '@/views/components/BlogPostModalComponent.vue';
import Modal from '@/views/components/ModalComponent.vue';
import Accordion from "@/views/components/accordion/AccordionComponent.vue";
import AccordionItem from "@/views/components/accordion/AccordionItemComponent.vue";
import httpClient from '@/httpClient';
import { decodeHTMLEntities } from '@/utils/stringUtils';

export default {
    name: 'DashboardPage',
    components: {AccordionItem, Accordion, Storelocator, Modal, BlogPostModalComponent },
    data() {
        return {
            showCwxIdQrModal: false,
            animated: {
                stats1: { visible: false },
                stats2: { visible: false },
                stats3: { visible: false },
                ads: { visible: false },
            },
            stats: {},
            ads: {},
            partnerNews: [],
            transactions: [],
            firstline: [],
            coinxlog: [],
            cmsContent: {},
            inspiration: [],
            modalVisible: false,
            selectedItem: null
        };
    },
    computed: {
        ...mapGetters(['user']),
        avatarUrl() {
            return this.getImageOrPlaceholderUrl(this.user.avatar);
        },
        heroImage() {
            if (this.user.customizedPWA && this.user.customizedPWA.heroImage) {
                return process.env.VUE_APP_CWX_BASE_URL + this.user.customizedPWA.heroImage;
            }
            return require('@/assets/images/dashboard/dashboard-hero-image.webp');
        }
    },
    methods: {
        ...mapActions(['logout']),
        formatDate(datetime) {
            const date = new Date(datetime);
            try {
                return new Intl.DateTimeFormat('de-DE', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                }).format(date);
            } catch {
                return '';
            }
        },
        qrAnimation(refName, out = false) {
            this.$refs[refName].style.opacity = "";
            this.$refs[refName].animateQRCode((targets, _x, _y, _count, entity) => (
                {
                    targets,
                    from: entity === 'module' ? 200 * Math.random() : 200,
                    duration: !out ? 800 : 400,
                    web: {
                        opacity: !out ? [0, 1] : [1, 0],
                        scale: !out ? [0.5, 1.1, 1] : [1, 1.25]
                    }
                })
            )
        },
        showNext(ident) {
            if (this.animated[ident]) {
                this.animated[ident].visible = true;
            }
        },
        getImageOrPlaceholderUrl(path) {
            return path ? this.getImageUrl(path) : require('@/assets/images/profile/default-avatar.png');
        },
        getImageUrl(path) {
            return process.env.VUE_APP_CWX_BASE_URL + path;
        },
        currency(number, locale = 'de-DE', currency = 'EUR') {
            const formatter = new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: currency
            });

            return formatter.format(number);
        },
        async fetchStats() {
            try {
                const response = await httpClient.get('/pwa/dashboard-stats')
                this.stats = response.data.data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async fetchAds() {
            try {
                const response = await httpClient.get('/pwa/dashboard-ads')
                this.ads = response.data.data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async fetchCmsContent() {
            try {
                const response = await httpClient.get('/pwa/cms-content')
                this.cmsContent = response.data.data;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async fetchProdctPartnerNews(e) {
            e.loading = true;
            try {
                const response = await httpClient.get('/pwa/partner-news')
                this.partnerNews = response.data.data
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                e.loading = false;
            }
        },
        async fetchTransactions(e) {
            e.loading = true;
            try {
                const response = await httpClient.get('/pwa/transactions')
                this.transactions = response.data.data
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                e.loading = false;
            }
        },
        async fetchFirstline(e) {
            e.loading = true;
            try {
                const response = await httpClient.get('/pwa/firstline')
                this.firstline = response.data.data
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                e.loading = false;
            }
        },
        async fetchCoinxLog(e) {
            e.loading = true;
            try {
                const response = await httpClient.get('/pwa/coinxlog')
                this.coinxlog = response.data.data
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                e.loading = false;
            }
        },
        async fetchBlogPosts(e) {
            e.loading = true;
            try {
                const response = await httpClient.get('/pwa/cwx-rss-feed');
                const xmlData = response.data.data;
                const parser = new DOMParser();
                const doc = parser.parseFromString(xmlData, "text/xml");

                // const items = doc.querySelectorAll("item");
                // const posts = [];
                const items = doc.getElementsByTagName("item");

                const parsedItems = Array.from(items).map(item => {
                    return {
                        title: item.querySelector("title")?.textContent.trim(),
                        description: item.querySelector("description")?.textContent.trim(),
                        link: item.querySelector("link")?.textContent.trim(),
                        guid: item.querySelector("guid")?.textContent.trim(),
                        category: item.querySelector("category")?.textContent.trim(),
                        pubDate: new Date(item.querySelector("pubDate")?.textContent.trim()),
                        creator: item.getElementsByTagNameNS("http://purl.org/dc/elements/1.1/", "creator")[0]?.textContent.trim(),
                        content: item.getElementsByTagNameNS("http://purl.org/rss/1.0/modules/content/", "encoded")[0]?.textContent.trim(),
                        enclosure: {
                            url: item.querySelector("enclosure")?.getAttribute("url"),
                            type: item.querySelector("enclosure")?.getAttribute("type")
                        }
                    };
                });
                this.inspiration = parsedItems.sort((a, b) => b.pubDate - a.pubDate);
                e.loading=false
            } catch (error) {
                console.error('Error fetching blog posts:', error);
                return []; // Return empty array on error
            }
        },
        showModal(item) {
            console.log('Showing modal for item:', item);
            this.selectedItem = item;
            this.modalVisible = true;
        },
        closeModal() {
            this.modalVisible = false;
            this.selectedItem = null;
        },
        decodeHTML(text) {
            return decodeHTMLEntities(text);
        }
    },
    mounted() {
        this.$refs['heroQr'].addEventListener('codeRendered', () => {
            this.qrAnimation('heroQr');
        })

        setTimeout(() => {
            this.animated.stats1.visible = true;
        }, 500)

        this.fetchStats();
        this.fetchAds();
        this.fetchCmsContent();
    }
}
</script>
