<template>
    <div class="form-signin">

        <form @submit.prevent="loginUser">

            <h1 class="h3 mb-1 fw-normal">Welcome to</h1>
            <img src="@/assets/images/brand/logo.svg" alt="crossworx" class="header-logo mb-3" />

            <div class="form-floating">
                <input type="text" v-model="username" id="usernameInput" class="form-control" placeholder="name@example.com" required>
                <label for="usernameInput">Email</label>
            </div>

            <div class="form-floating mb-3">
                <input type="password" v-model="password" id="passwordInput" class="form-control" placeholder="Password" required>
                <label for="passwordInput">Password</label>
            </div>

            <button type="submit" class="w-100 btn btn-lg btn-primary text-white">Login</button>

            <div class="mt-3">
                <a class="btn btn-link btn-sm" :href="passwordResetUrl">Forgot password</a>
            </div>

            <div v-if="loginError" class="mt-3 mb-0 alert alert-primary" role="alert">
                {{ loginError }}
            </div>

        </form>

        <p class="text-primary fw-bold my-1">You have been invited?</p>

        <p class="my-1">Then click on “Register here for free”</p>

        <p class="my-1 mb-3">Please note that we need the name of the person who invited you.</p>

        <button class="btn btn-primary text-white" @click="showRegisterModal = true">
            REGISTER HERE FOR FREE
        </button>

        <p class="text-primary fw-bold">Do you still have questions?</p>

        <a href="mailto:service@cwx.one" class="btn btn-outline-primary">
            YOUR SERVICE REQUEST TO US
        </a>

        <footer>
            <p>
                <strong>crossworx international GmbH</strong><br>
                Schaanerstrasse 27<br>
                LI-9490 Vaduz<br>
                Fürstentum Liechtenstein<br>
                <br>
                Telefon: +423 7982026<br>
                Email: service@cwx.one<br>
                www.crossworx.one
            </p>
        </footer>
    </div>

    <modal v-model:showModal="showRegisterModal" :hasBackdrop="true" title="Register now" class="text-center">
        <template #body>
            <iframe :src="registerIframeUrl" style="height:400px;"></iframe>
        </template>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from "@/views/components/ModalComponent.vue";

export default {
    name: 'LoginPage',
    components: {Modal},
    data() {
        return {
            username: '',
            password: '',
            showRegisterModal: false
        }
    },
    computed: {
        ...mapGetters(['loginError']),
        registerIframeUrl() {
            return `${process.env.VUE_APP_CWX_BASE_URL}/quick-register-form`;
        },
        passwordResetUrl() {
            return `${process.env.VUE_APP_CWX_BASE_URL}/passwortvergessen?pwa=1`;
        }
    },
    methods: {
        ...mapActions(['login']),
        async loginUser() {
            await this.login({ username: this.username, password: this.password })
            this.$router.push('/')
        }
    }
}
</script>

<style scoped>

    .alert {
        width: 100%;
        max-width: 330px;
        margin-bottom: 15px;
    }

    .form-signin {
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #f5f5f5;

        p {
          width: 100%;
          max-width: 315px;
          padding: 0;
          margin: 16px 0;
          font-size: 1.2em;
        }
    }

    .form-signin form {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;

        .form-floating:focus-within {
            z-index: 2;
        }

        input[type="email"] {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        input[type="password"] {
            margin-top: -1px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
</style>
