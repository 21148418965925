<template>
  <div v-if="show" class="post-modal-overlay" @click.self="closeModal">
    <div class="post-modal">
      <h2>{{ decodedTitle }}</h2>
      <p>
        <strong>Author:</strong> {{ item.creator }}<br>
        <strong>Category:</strong> {{ item.category }}<br>
      </p>
      <div v-html="decodedContent"></div>
      <div class="d-grid">
        <button @click="closeModal" class="btn btn-primary text-white">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { decodeHTMLEntities } from '@/utils/stringUtils';
export default {
    props: {
        show: Boolean,
        item: Object
    },
    computed: {
        decodedTitle() {
            return decodeHTMLEntities(this.item.title);
        },
        decodedContent() {
            return decodeHTMLEntities(this.item.content);
        }
    },
    methods: {
        formatDate(date) {
            return new Date(date).toLocaleDateString();
        },
        closeModal() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.post-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.post-modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 96%;
  max-height: 96%;
  overflow-y: auto;
}
</style>