<!-- src/components/BaseLayout.vue -->
<template>
    <div class="page" :class="currentRouteClass">
        <HeaderLayout />
        <main>
            <div class="toasts">

            </div>
            <router-view ref="routerView" @save-success="onSaveSuccess" @save-error="onSaveError"></router-view>
        </main>
        <ActionBarLayout @save-button-clicked="onSaveButtonClicked"/>
    </div>
</template>

<script>
import HeaderLayout from "@/views/layout/HeaderLayout.vue";
import ActionBarLayout from "@/views/layout/ActionBarLayout.vue";
import { useToast } from 'vue-toastification';
import {mapGetters} from "vuex";

export default {
    name: 'BaseLayout',
    components: { ActionBarLayout, HeaderLayout },
    computed: {
        ...mapGetters(['user']),
        currentRouteClass() {
            return 'page-' + ((this.$route.path ? this.$route.path.replaceAll('/','') : '') || 'dashboard');
        }
    },
    watch: {
        user: {
            immediate: true,
            handler(newUser) {
                this.updateCssVariables(newUser);
            }
        }
    },
    methods: {
        onSaveButtonClicked() {
            const routerView = this.$refs.routerView;
            const currentPageComponent = routerView.$.subTree.component.proxy; // The current page component instance

            if (currentPageComponent && typeof currentPageComponent.save === 'function') {
                currentPageComponent.save();
            }
        },
        onSaveSuccess() {
            const toast = useToast();
            toast.success('Saved successfully!', {
                position: "top-center",
                timeout: 2000,
                showCloseButtonOnHover: true,
            });
        },
        onSaveError() {
            const toast = useToast();
            toast.error('Error while saving - please check the inputs!', {
                position: "top-center",
                timeout: 5000,
                showCloseButtonOnHover: true,
            });
        },
        updateCssVariables(user) {
            if (user.customizedPWA) {
                const root = document.documentElement;
                if (user.customizedPWA.primaryColor) {
                    root.style.setProperty('--bs-primary', user.customizedPWA.primaryColor);
                    root.style.setProperty('--bs-primary-rgb', this.hexToRgb(user.customizedPWA.primaryColor));
                }
                if (user.customizedPWA.secondaryColor) {
                    root.style.setProperty('--bs-secondary', user.customizedPWA.secondaryColor);
                    root.style.setProperty('--bs-secondary-rgb', this.hexToRgb(user.customizedPWA.secondaryColor));
                }
                if (user.customizedPWA.heroOverlayColor) {
                    root.style.setProperty('--cwx-hero-bg', user.customizedPWA.heroOverlayColor);
                }
            }
        },
        hexToRgb(hex) {
            let bigint = parseInt(hex.slice(1), 16);
            let r = (bigint >> 16) & 255;
            let g = (bigint >> 8) & 255;
            let b = bigint & 255;
            return `${r},${g},${b}`;
        }
    },
    mounted() {
        this.updateCssVariables(this.user);
    }
}
</script>
