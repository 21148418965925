<template>
    <header class="page-header">
        <img :src="appLogo" alt="crossworx" class="header-logo" />
    </header>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'HeaderLayout',
    computed: {
        ...mapGetters(['user']),
        appLogo() {
            if (this.user.customizedPWA && this.user.customizedPWA.logo) {
                return process.env.VUE_APP_CWX_BASE_URL + this.user.customizedPWA.logo;
            }

            return require('@/assets/images/brand/logo.svg');
        }
    },
}
</script>